import styled from '@emotion/styled';
import CheckBoxStyle from './global/CheckBox.style';

export default styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  padding: 15px;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors[theme.complementaryColors.white]};
  border-radius: 4px;
  .submit-button {
    margin-top: 10px;
    .ad-count {
      margin-left: 0.5em;
    }
  }

  ${({ theme }) => theme.media.desktopSmall} {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: '. .' '. .' '. submit';
    .submit-button {
      grid-area: submit;
    }
  }

  ${CheckBoxStyle} {
    display: flex;
    align-items: center;
    gap: 12px;
  }
`;
