import styled from '@emotion/styled';

export default styled.label`
  cursor: pointer;
  & > .input {
    position: absolute;
    width: 20px;
    height: 20px;
    margin: 0;
    opacity: 0;
  }
  & > .overlay {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: solid 2px ${({ theme }) => theme.colors.quartz};
    background-color: ${({ theme }) => theme.colors.white};
    transition:
      background-color 150ms linear,
      border-color 150ms linear;
    display: flex;
    align-items: center;
    justify-content: center;
    .checkmark {
      transform: rotate(20deg) scale(0.3);
      color: ${({ theme }) => theme.colors.white};
      transition: transform 250ms step-end;
    }
  }

  &.checked > .overlay {
    border: solid 2px ${({ theme }) => theme.colors.pacificBlue};
    background-color: ${({ theme }) => theme.colors.pacificBlue};
    .checkmark {
      transform: rotate(0) scale(1);
      transition: transform 250ms cubic-bezier(0.4, 2, 0.8, 2);
    }
  }
  &.focused > .overlay {
    border: solid 2px ${({ theme }) => theme.colors.pacificBlue};
  }
  a {
    ${({ theme }) => theme.typography.underline};
  }
`;
