import React from 'react';
import { useState } from 'react';
import { Checkmark } from '../../app/helpers/icons';
import StyledCheckBox from './CheckBox.style';
import Icon from './Icon';

const CheckBox = ({
  children,
  className,
  checked,
  onChange,
  onBlur,
  onFocus,
  ...p
}) => {
  const [focused, setFocused] = useState(false);
  return (
    <StyledCheckBox
      className={
        (className || '') +
        (checked ? ' checked' : '') +
        (focused ? ' focused' : '')
      }
    >
      <input
        className="input"
        type="checkbox"
        checked={checked}
        onChange={() => {
          onChange?.(!checked);
        }}
        {...p}
        onFocus={(...a) => {
          setFocused(true);
          onFocus?.(...a);
        }}
        onBlur={(...a) => {
          setFocused(false);
          onBlur?.(...a);
        }}
      />
      <div className="overlay">
        <Icon className="checkmark" {...Checkmark} />
      </div>
      {typeof children !== 'string' ? (
        children
      ) : (
        <div className="label">{children}</div>
      )}
    </StyledCheckBox>
  );
};
export default CheckBox;
