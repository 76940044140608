import React, { useEffect, useMemo, useRef, useState } from 'react';
import StyledQuickSearch from './QuickSearch.style';
import TextField from './global/TextField';
import FieldSet from './global/FieldSet';
import Button from './global/Button';
import FancySelectField from './global/FancySelectField';
import Typography from './global/Typography';
import { debouncePromise } from '../app/helpers/debounce';
import { useTheme } from '@emotion/react';
import CheckBox from './global/CheckBox';
import { components } from 'react-select';
const adCountFormat = Intl.NumberFormat('nl-NL', {});

export const Option = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <CheckBox checked={props.isSelected}>{children}</CheckBox>
    </components.Option>
  );
};

export const ValueContainer = ({ children, ...props }) => {
  const { hasValue, selectProps } = props;
  const { value } = selectProps;

  let display;
  if (!hasValue) {
    display = children[0];
  } else if (value.length === 1) {
    display = value[0].label;
  } else {
    display = `${value.length} geselecteerd`;
  }

  return (
    <components.ValueContainer {...props}>
      {display}
      {children[1]}
    </components.ValueContainer>
  );
};

const QuickSearch = ({
  brandLabel,
  modelLabel,
  budgetLabel,
  brandPlaceholder,
  modelPlaceholder,
  budgetPlaceholder,
  keywordsLabel,
  keywordsPlaceholder,
  submitButtonText,
  getButtonHref,
  loadingText,
  brands: initialBrands,
  models: initialModels,
  budgets: initialBudgets,
  getBrandOptions,
  getModelOptions,
  getBudgetOptions,
  getAdCount,
  ...p
}) => {
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [budget, setBudget] = useState(null);
  const [keywords, setKeywords] = useState('');
  const [adCount, setAdCount] = useState(null);
  const theme = useTheme();

  const [brandProps, setBrandProps] = useState({
    options: initialBrands || [],
    isLoading: false,
    static: !!initialBrands?.length,
  });
  const [modelProps, setModelProps] = useState({
    options: initialModels || [],
    isLoading: false,
  });
  const [budgetProps, setBudgetProps] = useState({
    options: initialBudgets || [],
    isLoading: false,
    static: !!initialBudgets?.length,
  });

  const getters = useRef({});

  getters.current.brands = getBrandOptions;
  getters.current.models = getModelOptions;
  getters.current.budgets = getBudgetOptions;
  getters.current.adCount = useMemo(
    () => debouncePromise(getAdCount, 500),
    [getAdCount]
  );

  useEffect(async () => {
    try {
      const adCount = await getters.current.adCount({
        brands,
        models,
        budget,
        keywords,
      });
      setAdCount(adCount);
    } catch (e) {
      if (e === 'cancelled') return;
      console.error(e);
    }
  }, [
    JSON.stringify(brands),
    JSON.stringify(models),
    budget,
    keywords,
    getters.current,
  ]);

  useEffect(async () => {
    if (!getters.current.brands || brandProps.static) return;
    setBrandProps((p) => ({ ...p, isLoading: true }));
    const options = await getters.current.brands();
    setBrandProps((p) => ({ ...p, options, isLoading: false }));
  }, [brandProps.static, getters.current, getBrandOptions]);

  useEffect(async () => {
    if (!getters.current.models || !brands) {
      setModelProps((p) => ({ ...p, options: [], isLoading: false }));
      return;
    }
    setModels([]);
    setModelProps((p) => ({ ...p, isLoading: true }));
    const options = await getters.current.models({ value: brands });
    setModelProps((p) => ({ ...p, options, isLoading: false }));
  }, [JSON.stringify(brands), getters.current, getModelOptions]);

  useEffect(async () => {
    if (!getters.current.budgets || budgetProps.static) return;
    setBudgetProps((p) => ({ ...p, isLoading: true }));
    const options = await getters.current.budgets();
    setBudgetProps((p) => ({ ...p, options, isLoading: false }));
  }, [budgetProps.static, getters.current]);

  const buttonHref = useMemo(
    () =>
      getButtonHref({
        brands,
        models,
        budget,
        keywords,
      }),
    [getButtonHref, brands, models, budget, keywords]
  );

  return (
    <StyledQuickSearch {...p}>
      <FieldSet className="field-brand" label={brandLabel}>
        <FancySelectField
          isClearable
          isMulti
          hideSelectedOptions={false}
          value={brands}
          onChange={(value) => setBrands(value)}
          placeholder={brandPlaceholder}
          loadingMessage={loadingText}
          components={{ Option, ValueContainer }}
          styles={{
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
              return {
                ...styles,
                padding: '0.5rem 1rem',
                margin: '0',
                backgroundColor: isDisabled
                  ? undefined
                  : isSelected
                    ? theme.colors.aliceBlue
                    : isFocused
                      ? theme.colors.aliceBlue
                      : undefined,
                color: isDisabled
                  ? theme.colors.dimGray
                  : isSelected
                    ? theme.colors.trout
                    : theme.colors.trout,
                label: {
                  margin: '0 !important',
                },
                '.label': {
                  margin: '0 !important',
                },
              };
            },
            valueContainer: (base) => ({
              ...base,
              'white-space': 'nowrap',
              'flex-wrap': 'nowrap',
              gap: '0.25rem',
              width: '100%',
            }),
          }}
          {...brandProps}
        />
      </FieldSet>
      <FieldSet className="field-model" label={modelLabel}>
        <FancySelectField
          isClearable
          isMulti
          hideSelectedOptions={false}
          value={models}
          onChange={(value) => setModels(value)}
          placeholder={modelPlaceholder}
          loadingMessage={loadingText}
          components={{ Option, ValueContainer }}
          styles={{
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
              return {
                ...styles,
                padding: '0.5rem 1rem',
                margin: '0',
                backgroundColor: isDisabled
                  ? undefined
                  : isSelected
                    ? theme.colors.aliceBlue
                    : isFocused
                      ? theme.colors.aliceBlue
                      : undefined,
                color: isDisabled
                  ? theme.colors.dimGray
                  : isSelected
                    ? theme.colors.trout
                    : theme.colors.trout,
                label: {
                  margin: '0 !important',
                },
                '.label': {
                  margin: '0 !important',
                },
              };
            },
            valueContainer: (base) => ({
              ...base,
              'white-space': 'nowrap',
              'flex-wrap': 'nowrap',
              gap: '0.25rem',
              width: '100%',
            }),
          }}
          {...modelProps}
        />
      </FieldSet>
      <FieldSet className="field-budget" label={budgetLabel}>
        <FancySelectField
          isClearable
          value={budget}
          onChange={(value) => setBudget(value)}
          placeholder={budgetPlaceholder}
          loadingMessage={loadingText}
          {...budgetProps}
        />
      </FieldSet>
      <FieldSet className="field-keywords" label={keywordsLabel}>
        <TextField
          value={keywords}
          placeholder={keywordsPlaceholder}
          onChange={setKeywords}
          clearable
        />
      </FieldSet>
      <Button
        className="submit-button"
        variant="emerald"
        large
        href={buttonHref}
      >
        <span className="text">{submitButtonText}</span>
        <Typography className="ad-count" variant="control">
          {!!adCount && `(${adCountFormat.format(adCount)})`}
        </Typography>
      </Button>
    </StyledQuickSearch>
  );
};
export default QuickSearch;
