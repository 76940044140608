import React, { useCallback, useMemo } from 'react';
import * as Self from './module';
import { registerModule } from '../../app/modules/reactModules';
import Heading from '../../components/global/Heading';
import QuickSearch from '../../components/QuickSearch';
import PopularBrands from '../../components/PopularBrands';
import Button from '../../components/global/Button';
import Typography from '../../components/global/Typography';
import Icon from '../../components/global/Icon';
import { CheckmarkFull } from '../../app/helpers/icons';
import ModuleStyle from './module.style';
import { InstantSearch } from 'react-instantsearch-hooks-web';
import { searchClient } from '../../app/providers/searchProvider';
import currency from '../../app/helpers/currency';
import { adListing } from '../../app/helpers/url';
import budgetValues from '../../app/helpers/budgetValues';
import { indices } from '../../app/helpers/indices';
import { decode as decodeHtmlEntities } from 'html-entities';

export const getServerSideProps = async () => {
  return {
    props: {},
    maxage: 60,
    staleWhileRevalidate: 300,
  };
};

const QuickSearchAndPopularBrandsModule = ({
  wlModule,
  title,
  quicksearch,
  quicksearchBrandsSelect,
  popularBrands,
  usps,
  ...p
}) => {
  const getBrandOptions = useMemo(
    () => () => {
      const popularBrandsSection = [];
      const otherBrandsSection = [];

      quicksearch.brand?.items?.forEach((i) => {
        const b = { label: decodeHtmlEntities(i.content), value: i };
        if (i.popular === '1') {
          popularBrandsSection.push(b);
        } else {
          otherBrandsSection.push(b);
        }
      });
      return [
        {
          label: quicksearchBrandsSelect.popBrandsSection,
          options: popularBrandsSection,
        },
        {
          label: quicksearchBrandsSelect.otherBrandsSection,
          options: otherBrandsSection,
        },
      ];
    },
    [JSON.stringify(quicksearch?.brand?.items)]
  );

  const brandById = useMemo(
    () => Object.fromEntries(quicksearch.brand.items.map((i) => [i.id, i])),
    [quicksearch.brand.items]
  );

  const [modelsTableId, modelsTablePortalId] = useMemo(
    () => [
      document.getElementById('hubdb-models-table').content,
      document.getElementById('hubdb-portal-id').content,
    ],
    []
  );

  const getModelOptions = useMemo(
    () =>
      async ({ value: brands }) => {
        const rsp = await window.fetch(
          `https://api.hubapi.com/cms/v3/hubdb/tables/${modelsTableId}/rows?` +
            new URLSearchParams({
              portalId: modelsTablePortalId,
              ...(brands?.length
                ? { brand__in: brands.map((brand) => brand.value.id).join(',') }
                : {}),
              disabled: 0,
            })
        );
        const data = await rsp.json();
        return data.results?.map(({ values: value }) => ({
          label: value.name,
          value,
        }));
      },
    [modelsTableId, modelsTablePortalId]
  );
  const getBudgetOptions = useMemo(
    () => () =>
      budgetValues.map(({ from, to }) => ({
        label: `${currency(from)} - ${currency(to)}`,
        value: { from, to },
      })),
    []
  );

  const quickSearchProps = {
    ...quicksearch,
    loadingText: () => 'Laden...',
    getBrandOptions,
    getModelOptions,
    getBudgetOptions,
  };

  const popularBrandsProps = {
    brands: popularBrands?.brands || [],
    title: popularBrands?.title.content,
    seeAllButtonText: popularBrands?.button.content,
  };

  const getAdCount = async ({ brands, models, keywords, budget }) => {
    const filters = [];
    if (brands?.length) {
      filters.push(
        brands.map((brand) => `make.id:${brand.value.akId}`).join(' OR ')
      );
    }
    if (models?.length) {
      filters.push(
        models.map((model) => `model.id:${model.value.autokan_id}`).join(' OR ')
      );
    }
    if (budget) {
      filters.push(
        `default_monthly_payment:${budget.value.from} TO ${budget.value.to}`
      );
    }
    const rsl = await searchClient.search([
      {
        indexName: indices.vehicles,
        query: keywords || '',
        params: {
          analyticsTags: ['quicksearch'],
          hitsPerPage: 0,
          filters: filters.join(' AND '),
        },
      },
    ]);
    return rsl.results[0]?.nbHits || 0;
  };
  const getButtonHref = useCallback(
    ({ brands, models, budget, keywords }) => {
      let filters = undefined;
      if (budget || keywords) {
        filters = {};
        if (budget) filters.budget = `${budget.value.from}-${budget.value.to}`;
        if (keywords) filters.q = keywords;
      }
      return adListing({
        brandSlugs: brands?.length
          ? brands.map((brand) => brand.value.slug)
          : models?.length
            ? Object.keys(
                models.reduce((brands, model) => {
                  if (model.value.brand?.length) {
                    const brandId = model.value.brand[0]?.id;
                    if (brandById[brandId]?.slug) {
                      brands[brandById[brandId].slug] = true;
                    }
                  }
                  return brands;
                }, {})
              )
            : null,
        modelSlugs: models?.length
          ? models.map((model) => model.value.slug)
          : null,
        filters,
      });
    },
    [brandById]
  );

  return (
    <ModuleStyle
      wlModule={wlModule}
      color="pacificBlue"
      pb="30px"
      pt="15px"
      mt="0"
    >
      {!!title && <Heading color="white" title={title} className="title" />}

      <InstantSearch searchClient={searchClient} indexName={indices.vehicles}>
        <QuickSearch
          {...quickSearchProps}
          getAdCount={getAdCount}
          className="quick-search"
          getButtonHref={getButtonHref}
        />
      </InstantSearch>

      <div className="usps">
        {usps?.map((usp, i) => (
          <Typography key={i} className="usp" variant="heading5" color="white">
            <Icon fill="emerald" {...CheckmarkFull} />
            {usp.content}
          </Typography>
        ))}
      </div>

      {!!popularBrandsProps?.title && (
        <Heading
          color="white"
          variant="heading3"
          as="h3"
          className="popular-brands-title"
        >
          {popularBrandsProps.title}
        </Heading>
      )}
      <PopularBrands {...popularBrandsProps} className="popular-brands" />
      <Button
        onClick={() => {
          window.scrollToModule('BrandsOverview', 0, true, 60);
        }}
        large
        type="button"
        className="all-brands"
      >
        {popularBrandsProps.seeAllButtonText}
      </Button>
    </ModuleStyle>
  );
};

export default QuickSearchAndPopularBrandsModule;

registerModule({ QuickSearchAndPopularBrands: Self });
